<template>
  <div class="tabulation">
    <el-card class="chart-card"> 
      <!-- 搜索框 -->
      <div class="grabble">
        <div class="cat">
          <!-- 标题 -->
          <el-input
            class="hunt"
            v-model="headline"
            placeholder="请输入标题"
            size="large"
            @input="orangutan"
			clearable 
          />
          <!-- 可见范围 -->
          <el-select v-model="visible" placeholder="请选择可见范围" clearable  @change="range" size="large">
            <el-option label="直属机关工会" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="基层工会" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
          <!-- 状态 -->
          <el-select v-model="status" placeholder="请选择状态" clearable @change="phology" size="large">
            <el-option label="已发布" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="未发布" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
          <!-- 组织 -->
          <el-select v-model="organization" placeholder="请选择组织名称" @click="multiple" clearable @change="empty" size="large" @focus="closeIt" ref="selectIt">
          </el-select>


          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <div>
          <!-- <el-button
            style="margin-left: 10px"s
            type="primary"
            size="large"
            @click="shaped"
            ><img src="../assets/tongji.png" style="width:15px;margin-right: 6px" alt=""> 统计</el-button
          > -->
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <div class="summation">
        <div class="hint">统计 : {{altogether}}条</div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        :row-key="obtain => { return obtain.id}"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
      >
      <!-- 标题 -->
        <el-table-column align="center" :show-overflow-tooltip='true' label="标题" prop="title" />
      <!-- 所属工会 -->
        <el-table-column align="center" :show-overflow-tooltip='true' label="所属工会" prop="unionName" />
      <!-- 是否公开 -->
        <el-table-column align="center" :show-overflow-tooltip='true' label="可见范围" prop="seeing" :formatter="publics"/>
        <!-- 状态 -->
        <el-table-column
          label="状态"
          prop="isEnable"
          align="center"
          :show-overflow-tooltip='true'
          :formatter="condition"
          :empty-text="texts"
        >
        </el-table-column>
        <!-- 所在组织 -->
        <el-table-column
          label="创建时间"
          prop="startTime"
          align="center"
          :show-overflow-tooltip='true'
          :formatter="tiems"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="340">
          <template #default="scope">
            <el-button
              size="default"
              @click="shaped(scope.row.id)"
              class="bulur"
              ><img src="../assets/tongji.png" style="width:13px;margin-right: 6px" alt=""> 统计</el-button
            >
            <el-button
              size="default"
              @click="copyreader(scope.row.id)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
            <el-button size="default" @click="expurgate(scope.row.id)" class="red"
              ><el-icon class="cancel"> <Delete /> </el-icon>删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        layout="prev, pager, next,sizes"
        hide-on-single-page="true"
        :page-sizes="[6, 12, 18, 24]"
        :current-page="currentPage" 
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="问卷删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large"  type="primary" @click="affirm">确定</el-button>
          <el-button class="dsa" size="large" @click="remove=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="possessions" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
  </div>
</template>
<script>
import { Delete, EditPen, Search,Plus} from "@element-plus/icons-vue";
import { useRouter,useRoute  } from "vue-router";
import { ref,onMounted,onActivated } from 'vue'
import { DTlist,Zzlist,DTdelete } from '../utils/api'
import qs from 'qs'
import moments from 'moment'
import { ElMessage } from "element-plus";
export default {
  name:"answer",
  setup() {
    const route = useRoute();
    // 搜索加载
    const formative=ref(false)
    // 统计跳转
    let shaped=(id)=>{
      console.log(id)
      sessionStorage.setItem('summation', id)
      // router.push('/statistics')
	  router.push({
	  	name: 'statistics',
	  	params: {
	  		UserId: 1
	  	}
	  })
    }
    // 删除确定按钮
    let affirm =()=>{
      let data={
        id:wenjuanid.value
      }
      DTdelete(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          ElMessage({
            message: '删除成功',
            type: "success",
            center: true,
          });
          remove.value=false
          if(headline.value!==''||visible.value!==''||status.value!==''||organization.value!==''){
            search()
          }else{
            list()
          }
        }else{
          ElMessage({
            message: res.data.msg,
            type: "warning",
            center: true,
          });
          remove.value=false
          if(headline.value!==''||visible.value!==''||status.value!==''||organization.value!==''){
            search()
          }else{
            list()
          }
        }
      })
    }
    // 删除
    let remove=ref(false)
    let wenjuanid=ref(null)
    let expurgate=(id)=>{
      console.log(id)
      remove.value=true
      wenjuanid.value=id
    }
    // 加载中
    let texts=ref('加载中')
    // 组织框变化
    let empty=()=>{
      // if(headline.value==''&&visible.value==''&&status.value==''&&organization.value==''){
      //   list()
      // }
      Hcode.value=''
    }
    // 状态框变化
    // let phology=()=>{
    //   if(headline.value==''&&visible.value==''&&status.value==''&&organization.value==''){
    //     list()
    //   }
    // }
    // 可见范围框变换
    // let range=()=>{
    //   if(headline.value==''&&visible.value==''&&status.value==''&&organization.value==''){
    //     list()
    //   }
    // }
    // 标题输入框改变
    // const orangutan=()=>{
    //   if(headline.value==''&&visible.value==''&&status.value==''&&organization.value==''){
    //     list()
    //   }
    // }
    // 
	//隐藏下拉框
	let selectIt = ref()
	// 下拉隐藏
	let closeIt = () => {
		selectIt.value.blur();
	}
    // 搜索按钮
    let search1=()=>{
      formative.value=true
      search()
    }
    let search=()=>{
      console.log('标题',headline.value)
      console.log('可见范围',visible.value)
      console.log('状态',status.value)
      console.log('组织',Hcode.value)
      let data ={
        limit:branches.value,
        page:currentPage.value,
        title:headline.value,
        isEnable:status.value,
        unionCode:Hcode.value,
        seeing:visible.value
      }
      DTlist(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          formative.value=false
          filterTableData.value=res.data.data.page.list
          altogether.value=res.data.data.page.totalCount
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 获取code 
    let Hcode=ref('')
    // 选择名字
    let organization=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      organization.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    let texture =ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    let lang =()=>{
      Zzlist().then((res)=>{
        console.log(res)
        texture.value=res.data.data.wholeUnionList
      })
    }
    // 选择组织按钮
    let multiple=()=>{
      possessions.value=true
    }
    // 所有组织弹窗
    let possessions=ref(false)
    // 标题名称
    let headline=ref('')
    // 状态
    let status=ref('')
    // 可见范围
    let visible =ref('')
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(headline.value!==''||visible.value!==''||status.value!==''||organization.value!==''){
        search()
      }else{
        list()
      }
      
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(headline.value!==''||visible.value!==''||status.value!==''||organization.value!==''){
        search()
      }else{
        list()
      }
    }
    // 状态转换
    let condition = (row, colum,cellValue )=>{
      if(cellValue==1){
        return '已发布'
      }else if(cellValue==2){
        return '未发布'
      }
    }
    // 是否公开转换
    let publics=(row, colum,cellValue )=>{
      if(cellValue==1){
        return '直属机关工会'
      }else if(cellValue==2){
        return '基层工会'
      }
    }
    // 时间转换
    let tiems=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format().substring(0,10)
      }
    }
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      if(translate.value==1){
        headline.value=''
        visible.value=''
        status.value=''
        organization.value=''
        Hcode.value=''
        list()
        lang()
      }
    })
    onMounted(()=>{
      list()
      lang()
    })
    // 总数据
    let altogether=ref(null)
    // 答题问卷列表
    let branches=ref(6)
    let list =()=>{
      let data ={
        limit:branches.value,
        page:currentPage.value
      }
      DTlist(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          filterTableData.value=res.data.data.page.list
          altogether.value=res.data.data.page.totalCount
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 编辑跳转
    const copyreader = (id)=>{
      console.log(id)
      sessionStorage.setItem('DTid', id)
      router.push({ name: 'wjedit', params: { UserId: 1 }});
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/wjadd')
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      // 搜索加载
      search1,
      formative,
      // 统计跳转
      shaped,
      // 确定删除按钮
      affirm,
      // 删除
      remove,
      expurgate,
      // 加载中
      texts,
      empty,
      // phology,
      // range,
      // orangutan,
      // 搜索按钮
      search,
      // 所有组织弹窗
      organization,
      dendrogram,
      texture,
      defaultProps,
      multiple,
      possessions,
      // 标题名称
      headline,
      // 状态
      status,
      // 可见范围
      visible,
      // 分页
      altogether,
      pagesize,
      currentPage,
      handleCurrentChange,
      handleSizeChange,
      // 状态转换
      condition,
      // 是否公开转换
      publics,
      // 时间转换
      tiems,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData,
	  closeIt,
	  selectIt,
    };
  },
  components: {
    Delete,
    EditPen,
    Search,
    Plus
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.cat{
  width: 70%;
  display: flex;
}
.el-select--large{
  width: 22%;
  margin-right: 10px;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 145px);
  overflow: auto;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
/* ::-webkit-scrollbar{display:none} */
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
